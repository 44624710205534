.card-action {
    align-self: center;
    align-items: center;
    background-color: var(--clr-accent-500);
    border: none;
    border-radius: 15px;
    color: var(--clr-primary-200);
    display: flex;
    flex-direction: row;
    float: right;
    font-size: 1.25em;
    height: 3.5rem;
    justify-content: center;
    text-decoration: none;
    width: 100%;
}
.card-action:hover {
    background-color: var(--clr-neutral-800);
}
.card-action img {
    height: 1.5em;
    margin-right: 1rem;
}

.card-container {
    margin: auto;
    width: 90%;
}

.card-left, .card-right {
    background-color: var(--clr-primary-300);
    color: var(--clr-neutral-900);
    display: flex;
    flex-direction: column;
    height: min-content;
    margin-top: 2rem;
    overflow: hidden;
    padding: 1rem;
}

.card-left {
    border-radius: 50px 0px 0px 50px;
    border-right: 10px solid var(--clr-accent-500);
}

.card-right {
    border-radius: 0px 50px 50px 0px;
    border-left: 10px solid var(--clr-accent-500);
}

.card-image {
    grid-area: image;
}

.card-image img {
    display: block;
    margin: auto;
    object-fit: cover;
    object-position: center;
    width: min(100%, 20rem);    
}

.card-details {
    grid-area: details;
    padding: 1rem;
}

.card-title::after {
    border-bottom: 1px solid var(--clr-neutral-900);
    content: "";
    display: block;
    width:100%; 
}

@media (min-width: 860px) {
    .card-container {
        display: flex;
        flex-direction: column;
        height: 75vh;
        justify-content: center;
    }

    .card-left {
        display: grid;
        grid-template-areas:
        "image details";
        grid-template-columns: 25% 75%;
    }

    .card-right {
        display: grid;
        grid-template-areas:
        "details image";
        grid-template-columns: 75% 25%;
    }

    .card-column {
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .card-container {
        width: max(50%, 50rem);
    }  
}
